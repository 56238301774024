<template>
  <!-- 审批 -->
  <div class="wrap">
    <div class="search">
      <div class="search-left">
        <div class="gap">
          日期范围
          <DatePicker type="daterange"
                      format="yyyy-MM-dd"
                      placement="bottom-end"
                      placeholder="请选择日期"
                      :editable="false"
                      style="width: 220px"
                      @on-change="dateChangeHandler">
          </DatePicker>
          <!-- <DatePicker type="daterange" placement="bottom-end" placeholder="Select date" style="width: 200px"></DatePicker> -->
        </div>
        <div class="gap task-type">
          <Select v-model="adder"
                  style="width: 180px"
                  clearable
                  @on-change="initReviewlist2"
                  placeholder="飞手">
            <Option v-for="item in user_list"
                    :key="item.user_id"
                    :value="item.user_id">
              {{ item.name }}</Option>
          </Select>
        </div>
        <div class="gap task-type">
          <Select v-model="type_id"
                  style="width: 180px"
                  clearable
                  @on-change="initReviewlist2"
                  placeholder="审批类型">
            <Option v-for="item in taskTypeList"
                    :key="item.type_id"
                    :value="item.type_id">
              {{ item.type_name }}</Option>
          </Select>
          <div @click="taskModel = !taskModel"
               class="task-type-edit">
            <Icon type="ios-create-outline"
                  size="22" />
          </div>
        </div>
        <div class="gap task-type">
          <Select v-model="examineStatus"
                  style="width: 180px"
                  clearable
                  @on-change="initReviewlist2"
                  placeholder="审批状态">
            <Option v-for="item in examineStatusList"
                    :key="item.value"
                    :value="item.value">
              {{ item.label }}</Option>
          </Select>

        </div>
      </div>
      <div class="search-right">
        <Button type="info"
                @click="showReviewModal(1)">新增审核</Button>
      </div>
    </div>
    <div class="tableList">
      <Table :columns="NFcolumns"
             :data="tableList"></Table>
      <div class="page"
           v-if="parseInt(total)>20">
        <Page :total="total"
              class="page"
              :current="parseInt(page)"
              @on-change="pageChange" />
      </div>
    </div>
    <!-- 任务类型编辑窗口 -->
    <Modal v-model="taskModel"
           title="审批类型管理"
           width="360"
           footer-hide
           @on-cancel="taskModelCancel">
      <div>
        <Form ref="formInline"
              class="addTask"
              :model="formInline"
              :rules="ruleInline"
              inline>
          <FormItem prop="newTaskType"
                    style="width: 255px">
            <Input type="text"
                   v-model="formInline.newTaskType"
                   placeholder="新增类型">
            </Input>
          </FormItem>
          <FormItem>
            <div @click="addTaskTypeBtn('formInline')"
                 class="addTaskTypeBtn">
              新增
            </div>
          </FormItem>
        </Form>
      </div>
      <div class="taskList">
        <div class="taskList-item"
             v-for="item in taskTypeList"
             :key="item.id">
          <div v-show="!item.edit">{{ item.type_name }}</div>
          <Input type="text"
                 class="taskList-item-editInput"
                 v-show="item.edit"
                 v-model="item.editName"
                 @on-blur="editInputTaskTypeName(item)"
                 :placeholder="item.type_name">
          </Input>
          <div>
            <Icon type="ios-create-outline"
                  class="taskList-item-edit"
                  @click="editTaskTypeName(item)"
                  size="22" />
            <Icon type="md-close"
                  @click="delTaskType(item.type_id)"
                  class="taskList-item-del"
                  size="22" />
          </div>
        </div>
      </div>
      <div slot="footer"></div>
    </Modal>

    <!-- 审核窗口 -->
    <Modal v-model="showModal"
           @on-cancel="cancelModal"
           :loading="modal_loading">
      <p slot="header"
         style="font-size:16px">{{modalType == 1?"新增审批":"查看审核"}}<span v-if="reviewForm.state === 0"
              style="color:#FF8900;margin-left:10px">待处理</span><span v-if="reviewForm.state === 1"
              style="color:#00ED6D;margin-left:10px">审批通过</span><span v-if="reviewForm.state === 2"
              style="color:#F56C6C;margin-left:10px">审批拒绝</span></p>
      <Form ref="reviewForm"
            :model="reviewForm"
            :rules="reviewFormRules">
        <FormItem prop="reviewName"
                  label="审批名称:">
          <Input type="text"
                 :readonly="modalType === 2"
                 v-model="reviewForm.reviewName"
                 placeholder="请填写审批名称"
                 maxlength="20"
                 style="width: 400px"
                 show-word-limit>
          </Input>
        </FormItem>
        <FormItem v-if="modalType===1"
                  prop="type_id"
                  label="审批类型:">
          <Select v-model="reviewForm.type_id"
                  clearable
                  style="width: 200px"
                  placeholder="请选择审批类型">
            <Option v-for="item in userTaskTypeList"
                    :key="item.type_id"
                    :value="item.type_id">
              {{ item.type_name }}</Option>
          </Select>
        </FormItem>
        <FormItem v-else
                  prop="type_id"
                  label="审批类型:">
          {{reviewForm.type_id}}
        </FormItem>
        <FormItem prop="sTime"
                  label="开始时间:">
          <DatePicker type="datetime"
                      style="width: 200px"
                      :editable="false"
                      :readonly="modalType === 2"
                      format="yyyy-MM-dd HH:mm"
                      placeholder="请选择开始时间"
                      v-model="reviewForm.sTime"></DatePicker>
        </FormItem>
        <FormItem prop="eTime"
                  label="结束时间:">
          <DatePicker type="datetime"
                      style="width: 200px"
                      :readonly="modalType === 2"
                      :editable="false"
                      format="yyyy-MM-dd HH:mm"
                      placeholder="请选择结束时间"
                      v-model="reviewForm.eTime"></DatePicker>
        </FormItem>
        <FormItem prop="note"
                  label="备注:">
          <Input type="textarea"
                 v-model="reviewForm.remark"
                 :readonly="modalType === 2"
                 maxlength="60"
                 :autosize="{minRows: 2,maxRows: 5}"
                 show-word-limit>
          </Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <div v-if="modalType === 1">
          <Button type="primary"
                  long
                  @click="addReview">完成</Button>
        </div>
        <div v-else>
          <div v-if="reviewForm.state===0">
            <div v-if="userInfo.is_manager===1">
              <Button type="primary"
                      @click="reviewEdit(1)">审批通过</Button>
              <Button type="error"
                      @click="reviewEdit(2)">审批拒绝</Button>
              <Button v-if="userInfo.user_id === reviewForm.adder"
                      type="warning"
                      @click="revocation">撤销</Button>
            </div>
            <div v-if="userInfo.is_manager===0 && userInfo.user_id === reviewForm.adder">
              <Button type="warning"
                      @click="revocation"
                      long>撤销</Button>
            </div>

          </div>

        </div>
      </div>

    </Modal>

    <!-- 删除弹窗 -->
    <Modal v-model="delModal"
           title="提示"
           @on-ok="delConfirm">
      <p>确认删除吗？</p>
    </Modal>
  </div>
</template>

<script>
import api from '@/utils/api.js';

export default {
  name: 'review',
  data: function () {
    return {
      userInfo: {},
      showModal: false, // 审批窗口
      modalType: 1, // 审批窗口类型
      reviewForm: {
        reviewName: '', // 审批名称
        type_id: '', // 审批类型id
        sTime: '', // 开始时间
        eTime: '', // 结束时间
        remark: '', // 备注
        state: '', // 审批状态
        adder: '', // 申请者id
        id: '', // 审批id
      }, // 审批窗口信息
      modal_loading: true,
      dateRange: [],

      taskModel: false, // 任务类型弹窗
      newTaskModel: false, // 新增任务弹窗
      newExecutor: '', // 新建任务里的执行人
      newType_id: '', // 新建任务里的任务类型
      newTaskNameRemark: '',
      adder: '',
      user_list: [],
      taskTypeList: [],
      userTaskTypeList: [],
      examineStatus: -1,
      examineStatusList: [
        { label: '待处理', value: 0 },
        { label: '审核拒绝', value: 2 },
        { label: '审核通过', value: 1 },
      ],
      start_time: '',
      newStart_time: '',
      newEnd_time: '',
      teamID: -1, // 团队id(值为-1时,获取下级团队所有)
      type_id: '',
      tableList: [],
      Range: [1, 2, 3],
      page: 1,
      total: 10,
      images: [],
      delModal: false,
      NFcolumns: [
        {
          title: '审批名称',
          key: 'review_name',
          align: 'center',
        },
        {
          title: '审批类型',
          key: 'review_type_name',
          align: 'center',
        },
        {
          title: '开始时间',
          key: 'start_time',
          align: 'center',
        },
        {
          title: '结束时间',
          key: 'end_time',
          align: 'center',
        },
        {
          title: '申请人',
          key: 'adder_name',
          align: 'center',
        },
        {
          title: '状态',
          key: 'state',
          width: 120,
          align: 'center',
          render: (h, params) => {
            let str = '';
            let color = '';
            switch (params.row.state) {
              case 0:
                str = '待处理';
                color = '#FF8900';
                break;
              case 1:
                str = '审批通过';
                color = '#00ED6D';
                break;
              case 2:
                str = '审批拒绝';
                color = '#F56C6C';
                break;
              default:
                str = '未知状态';
                color = '#000';
                break;
            }

            return h(
              'span',
              {
                style: {
                  color: color,
                },
              },
              str
            );
          },
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                  },
                  style: {
                    marginRight: '15px',
                  },
                  on: {
                    click: () => {
                      this.showReviewModal(2, params.row);
                    },
                  },
                },
                '查看'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                  },
                  style: {
                    marginRight: '15px',
                  },
                  on: {
                    click: () => {
                      this.del(params.row.id);
                    },
                  },
                },
                '删除'
              ),
            ]);
          },
        },
      ],
      formInline: {
        newTaskType: '',
      },
      ruleInline: {
        newTaskType: [
          {
            required: true,
            message: '不能为空',
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 3,
            message: '字数在3-15之间',
            trigger: 'blur',
          },
        ],
      },
      // 审批弹窗规则
      reviewFormRules: {
        reviewName: {
          required: true,
          message: '名称不能为空',
          trigger: 'blur',
        },
        type_id: {
          required: true,
          message: '审批类型不能为空',
          trigger: 'change',
          // 选择器组件的表单规则判断
          pattern: /.+/,
        },
        sTime: {
          required: true,
          message: '开始时间不能为空',
          trigger: 'change',
          pattern: /.+/,
        },
        eTime: {
          required: true,
          message: '结束时间不能为空',
          trigger: 'change',
          pattern: /.+/,
        },
      },
    };
  },
  mounted() {
    if (sessionStorage.getItem('page')) {
      this.page = sessionStorage.getItem('page');
    }
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    if (sessionStorage.getItem('team_id') === null) {
      this.teamID = this.userInfo.team_id;
      this.initReviewlist();
      this.getTaskList();
    } else {
      this.teamID = Number(sessionStorage.getItem('team_id'));
      this.initReviewlist();
      this.getTaskList();
    }
    this.$post(api.taskListAndPerson(), {
      team_id: this.userInfo.team_id,
    }).then((res) => {
      if (res.code === 1) {
        let data = res.data.type_list;
        const temp = data.map((el) => {
          el.edit = false;
          el.editName = el.type_name;
        });
        this.userTaskTypeList = data;
      }
    });
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.page = 1;
      sessionStorage.setItem('page', 1); // 设置初始翻页
      this.teamID = value;
      this.initReviewlist();
      this.getTaskList();
    });
  },
  watch: {
    newTaskModel(val) {
      if (val == true) {
        this.initDraw(); // 新增时绘制多边形
      }
    },
  },
  methods: {
    // 删除弹窗
    delConfirm() {
      const params = { review_id: this.reviewForm.id };
      this.$post(api.ReviewDelete(), params).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer);
          this.initReviewlist();
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },

    //  打开审核弹窗
    showReviewModal(type, p) {
      this.modalType = type;
      if (type === 2) {
        console.log(p);
        // 查看
        this.reviewForm.reviewName = p.review_name;
        // let arr = this.taskTypeList.filter((el) => el.type_id == p.review_type);
        // this.reviewForm.type_id = arr.length && arr[0].type_id;
        this.reviewForm.type_id = p.review_type_name;
        this.reviewForm.sTime = p.start_time;
        this.reviewForm.eTime = p.end_time;
        this.reviewForm.remark = p.note;
        this.reviewForm.state = p.state;
        this.reviewForm.adder = p.adder;
        this.reviewForm.id = p.id;
      } else {
        this.reviewForm.reviewName = '';
        this.reviewForm.type_id = '';
        this.reviewForm.sTime = '';
        this.reviewForm.eTime = '';
        this.reviewForm.remark = '';
        this.reviewForm.state = '';
        this.reviewForm.adder = '';
        this.reviewForm.id = '';
      }
      this.showModal = true;
    },

    // 审核类型-编辑
    editInputTaskTypeName(item) {
      this.$post(api.taskTypeEdit(), {
        type_id: item.type_id,
        type_name: item.editName,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer);
          item.type_name = item.editName;
          this.getTaskList();
        } else {
          item.editName = item.type_name;
          this.$Message.warning(res.msg_customer);
        }
      });
    },
    editTaskTypeName(item) {
      this.taskTypeList.map((el) => (el.edit = false));
      item.edit = !item.edit;
    },
    addTaskTypeBtn(name) {
      let that = this;
      this.$refs[name].validate((valid) => {
        this.$post(api.taskTypeAdd(), {
          type_name: that.formInline.newTaskType.trim(),
          team_id: this.teamID > 0 ? this.teamID : this.userInfo.team_id,
        }).then((res) => {
          if (res.code === 1) {
            this.$Message.success(res.msg_customer);
            this.formInline.newTaskType = '';
            this.getTaskList();
          } else {
            this.$Message.warning(res.msg_customer);
          }
        });
      });
    },
    delTaskType(id) {
      console.log(id);
      this.$post(api.taskTypeDelete(), {
        type_id: id,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success('删除成功!');
          this.getTaskList();
        }
      });
    },
    taskModelCancel() {
      this.initReviewlist();
    },

    del(id) {
      if (this.userInfo.is_manager === 1) {
        this.delModal = true;
        this.reviewForm.id = id;
      } else {
        this.$Message.warning('您没有操作权限');
      }
    },
    // 翻页
    pageChange(p) {
      this.page = p;
      sessionStorage.setItem('page', p);
      this.initReviewlist();
    },
    // 选择器
    initReviewlist2() {
      this.page = 1;
      sessionStorage.setItem('page', 1); // 设置初始翻页
      this.initReviewlist();
    },
    // 获取审批列表
    initReviewlist() {
      // let s = 0;
      let form = {};
      if (this.examineStatus == -1) {
        // s = '';
        form = {
          page: this.page,
          team_id: this.teamID > 0 ? this.teamID : this.userInfo.team_id,
          limit: 10,
          end_time: this.end_time,
          start_time: this.start_time,
          review_type: this.type_id,
          adder: this.adder,
        };
      } else {
        // s = this.examineStatus;
        form = {
          page: this.page,
          team_id: this.teamID > 0 ? this.teamID : this.userInfo.team_id,
          limit: 10,
          end_time: this.end_time,
          start_time: this.start_time,
          review_type: this.type_id,
          adder: this.adder,
          state: this.examineStatus,
        };
      }
      this.$post(api.ReviewList(), form).then((res) => {
        if (res.code === 1) {
          this.total = res.data.total;
          this.tableList = res.data.data;
        }
      });
    },
    dateChangeHandler(e) {
      if (e) {
        this.start_time = e[0];
        this.end_time = e[1];
      }
      this.page = 1;
      sessionStorage.setItem('page', 1); // 设置初始翻页
      this.initReviewlist();
    },
    newDateChangeHandler(e) {
      if (e) {
        this.newStart_time = e[0];
        this.newEnd_time = e[1];
      }
    },
    //获取任务类型
    getTaskList() {
      this.$post(api.taskListAndPerson(), {
        // client: 'web',
        team_id: this.teamID > 0 ? this.teamID : this.userInfo.team_id,
      }).then((res) => {
        if (res.code === 1) {
          let data = res.data.type_list;
          const temp = data.map((el) => {
            el.edit = false;
            el.editName = el.type_name;
          });
          this.taskTypeList = data;
          this.user_list = res.data.user_list;
        }
      });
    },

    // 新增审核
    addReview() {
      this.$refs['reviewForm'].validate((valid) => {
        if (valid) {
          this.modal_loading = false;
          //   this.showModal = false;
          this.$post(api.ReviewAdd(), {
            review_name: this.reviewForm.reviewName,
            review_type: this.reviewForm.type_id,
            start_time: this.$moment(this.reviewForm.sTime).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            end_time: this.$moment(this.reviewForm.eTime).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            note: this.reviewForm.remark,
            team_id: this.userInfo.team_id,
          }).then((res) => {
            if (res.code === 1) {
              this.$Message.success('添加成功');
              this.modal_loading = false;
              this.showModal = false;
              this.$refs['reviewForm'].resetFields();
              this.initReviewlist();
            } else {
              this.$Message.error(res.msg_customer);
              this.modal_loading = false;
            }
          });
        }
      });
    },

    // 弹窗关闭
    cancelModal() {
      this.$refs['reviewForm'].resetFields();
    },

    // 审核状态编辑
    reviewEdit(t) {
      this.$post(api.ReviewEdit(), {
        state: t,
        review_id: this.reviewForm.id,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success('审批成功');
          this.modal_loading = false;
          this.showModal = false;
          this.$refs['reviewForm'].resetFields();
          this.initReviewlist();
        } else {
          this.$Message.error(res.msg_customer);
          this.modal_loading = false;
        }
      });
    },
    revocation() {
      this.$post(api.ReviewDelete(), {
        review_id: this.reviewForm.id,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success('撤销成功');
          this.modal_loading = false;
          this.showModal = false;
          this.$refs['reviewForm'].resetFields();
          this.initReviewlist();
        } else {
          this.$Message.error(res.msg_customer);
          this.modal_loading = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.red {
  color: red;
  padding-right: 5px;
}

.newTaskContainer {
  display: flex;
  .newTaskContainer-left {
    width: 20%;
    padding-right: 16px;
    & > div {
      padding: 10px 0;
      & > p {
        padding: 10px 0;
        // color: #999999;
      }
    }
  }
}
.gap {
  margin-left: 20px;
}
.addTask {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .addTaskInput {
    flex: 1;
  }
  .addTaskTypeBtn {
    width: 50px;
    text-align: right;
    cursor: pointer;
    color: #2d8cf0;
  }
}
.taskList {
  .taskList-item {
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .taskList-item-edit,
  .taskList-item-del {
    cursor: pointer;
  }
  .taskList-item-del {
    margin-left: 10px;
  }
  .taskList-item-editInput {
    width: 250px;
  }
}
.wrap {
  background-color: #f8f8f9;
  height: 100%;
  padding: 10px 20px;
  .task-type {
    display: flex;
    align-items: center;
    .task-type-edit {
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .search {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    .search-left {
      display: flex;
      flex: 1;
    }
    .search-right {
      width: 95px;
    }
  }
  .tableList {
    background-color: #fff;
    overflow: hidden;
    .page {
      padding: 10px 20px;
      float: right;
    }
  }
  /deep/ .green {
    color: #00ed6d;
  }
  /deep/ .red {
    color: #f56c6c;
  }
}

.tabCard {
  display: flex;
  div {
    width: 100px;
    border: 1px solid #e5e5e5;
    padding: 4px 0px;
    text-align: center;
    cursor: pointer;
  }
  .activeTab {
    background-color: #2196f3;
    color: #fff;
  }
}
</style>